@import url("https://fonts.cdnfonts.com/css/satoshi");
@import url("https://fonts.cdnfonts.com/css/instrument-sans");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Instrument Sans", "Satoshi", sans-serif !important;
}

.scrollable {
  scrollbar-width: 5px !important;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}
